import React, { createContext, useContext, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useAnalytics } from "./analytics-context-hooks";
import { APP_TAGS } from "./analytics-context-tags";
import { ACTIONS } from "./analytics-context-actions";
import { initializeDataDog } from "../../utils/initialize-data-dog";
import { GetEnvironment } from "../../utils/Environment";
import { createStatusMessage } from "./analytics-context-utils";
import { useCallback } from "react";
import { useRef } from "react";

const defaultEditFormats = {
  textAlignment: "",
  addColor: "",
  color: "",
  fontName: "",
  fontSize: "",
  crop: "",
  order: "",
  rotate: "",
  size: "",
  move: null,
  eventAction: null,
  isTextModified: null,
};

export const AnalyticsContext = createContext({});

export const AnalyticsContextProvider = ({ children, shouldInjectScripts }) => {
  const { buildTag, track, dataLayerAvailable } = useAnalytics();
  const [productID, setProductID] = useState("");
  const [projectID, setProjectID] = useState("");
  const isDataLayerAvailable = useRef(false);
  const currentEnvironment = GetEnvironment();

  useEffect(() => {
    if (productID !== "" && projectID !== "") {
      trackStartFlow();
    }
  }, [productID, projectID]);

  useEffect(() => {
    console.log("Current env: " + currentEnvironment);
  }, [currentEnvironment])

  useEffect(() => {
    if (dataLayerAvailable) {
      isDataLayerAvailable.current = true;
    }
  }, [dataLayerAvailable]);

  const trackEvent = (tagType, trackType, options, keysToDelete) => {
    if (isDataLayerAvailable.current) {
      if (!keysToDelete) keysToDelete = ["messages", "modal.messages"];
      const tag = buildTag(tagType, options, keysToDelete);
      // @todo remove console log when analytics complete
      // eslint-disable-next-line no-console
      console.log(`tagType: ${tagType}`, tag);
      track(trackType);
    }
  };

  const trackStartFlow = () => {
    const { START_FLOW } = APP_TAGS;
    const options = { productID, projectID };
    trackEvent(START_FLOW, "page", options);
  };

  const trackExitFlow = () => {
    const { EXIT_FLOW } = APP_TAGS;
    const options = { productID, projectID };
    trackEvent(EXIT_FLOW, "page", options);
  };

  // DG Landing page events
  const trackView = () => {};

  const trackPageLoaded = () => {};

  const trackAnimationLoadFailure = () => {};
  const trackAnimationPlayStart = () => {
    const { PLAY_START } = APP_TAGS;
    const options = { productID, projectID };
    trackEvent(PLAY_START, "link", options);
  };
  const trackAnimationPlayCompleted = () => {
    const { PLAY_COMPLETE } = APP_TAGS;
    const options = { productID, projectID };
    trackEvent(PLAY_COMPLETE, "link", options);
  };

  const trackIOSAppDownloadButtonClick = () => {
    const { BUTTON_DOWNLOAD_IOS } = APP_TAGS;
    const options = { productID, projectID };
    trackEvent(BUTTON_DOWNLOAD_IOS, "link", options);
  };

  const trackMoreButtonClick = () => {
    const { BUTTON_MORE } = APP_TAGS;
    const options = { productID, projectID };
    trackEvent(BUTTON_MORE, "link", options);
  };

  const trackCoverButtonClick = () => {
    const { BUTTON_COVER } = APP_TAGS;
    const options = { productID, projectID };
    trackEvent(BUTTON_COVER, "link", options);
  };

  const trackInsideButtonClick = () => {
    const { BUTTON_INSIDE } = APP_TAGS;
    const options = { productID, projectID };
    trackEvent(BUTTON_INSIDE, "link", options);
  };

  const trackAbandon = (animationTime) => {
    const { APP_ABANDON } = APP_TAGS;
    const options = { animationTime, productID, projectID };
    trackEvent(APP_ABANDON, "link", options);
  };

  const trackPinchGesture = (pinchDirection) => {
    const { PINCH } = APP_TAGS;
    const options = { pinchDirection, productID, projectID };
    trackEvent(PINCH, "link", options);
  };

  const trackDoubleTapGesture = (zoomDirection) => {
    const { DOUBLETAP } = APP_TAGS;
    const options = { zoomDirection, productID, projectID };
    trackEvent(DOUBLETAP, "link", options);
  };

  const trackError = (messages, action) => {
    const { APP_ERROR } = APP_TAGS;
    const options = { messages, action, productID, projectID };
    trackEvent(APP_ERROR, "link", options);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        trackStartFlow,
        trackExitFlow,
        trackView,
        trackPageLoaded,
        trackAnimationLoadFailure,
        trackAnimationPlayStart,
        trackAnimationPlayCompleted,
        trackIOSAppDownloadButtonClick,
        trackMoreButtonClick,
        trackCoverButtonClick,
        trackInsideButtonClick,
        trackAbandon,
        trackPinchGesture,
        trackDoubleTapGesture,
        trackError,
        setProductID,
        setProjectID,
        ACTIONS,
        createStatusMessage,
      }}
    >
      {shouldInjectScripts && (
        <HelmetProvider context={{}}>
          <Helmet defer={false}>
            {currentEnvironment === "prod" ? (
              <>
                <script
                  type="text/javascript"
                  src="https://www.hallmark.com/on/demandware.store/Sites-hmk-onesite-Site/default/DataLayer-Show"
                ></script>
                <script
                  src="https://assets.adobedtm.com/5810f1912eae/124c804a7bd6/launch-2db0eb99f565.min.js"
                  async
                ></script>
              </>
            ) : (
              <>
                <script
                  type="text/javascript"
                  src="https://www.dev.hallmark.com/on/demandware.store/Sites-hmk-onesite-Site/default/DataLayer-Show"
                ></script>
                <script
                  src="https://assets.adobedtm.com/5810f1912eae/124c804a7bd6/launch-f4614507d2e1-development.min.js"
                  async
                ></script>
              </>
            )}
          </Helmet>
        </HelmetProvider>
      )}
      {children}
    </AnalyticsContext.Provider>
  );
};

// Hooks
export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error(
      "useAnalyticsContext must be used within AnalyticsProvider"
    );
  }

  return context;
};
