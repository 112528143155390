import "./StaticUnityViewWithCustomName.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";
import { LoadingIndicator } from "@hallmark/web.core.feedback.loading-indicator";
import { GetBaseUrl } from "../../../utils/Environment";
import { useAnalyticsContext } from "../../../context/analytics-context";
import { useAnalytics } from "../../../context/analytics-context/analytics-context-hooks";
import { useAppContext } from "../../../context/app-context/app-context";

const StaticUnityViewWithCustomName = (props) => {
  const { inputData, recipientName, cardImages, cardFormat } = props;
  const [unityReady, setUnityReady] = useState(false);
  const { dataLayerAvailable } = useAnalytics();
  const [loadingProgression, setLoadingProgression] = useState(0);

  const defaultViewStyle = {
    height: "60vh",
  };

  const fullViewStyle = {
    height: "75vh",
  };

  const [viewStyle, setViewStyle] = useState(defaultViewStyle);

  const projectDataModel = {
    projectId: "123456",
    templateType: "portrait",
    previewMode: false,
    showCoverPreview: false,
    qualityLevel: 1,
    //2 - 0.5
    //4 - 0.65
    //5 - 0.75
    //6 - 0.85
    cardPanelData: [
      {
        index: 0,
        panelImage:
          "https://content.hallmark.com/webassets/PGM2901/PGM2901_P1_Background.png",
      },
      {
        index: 1,
        panelImage:
          "https://content.hallmark.com/webassets/PGM2901/PGM2901_P2-3_Background.png",
      },
    ],
  };

  const {
    trackAbandon,
    trackAnimationPlayStart,
    trackAnimationPlayCompleted,
    trackPageLoaded,
    trackPinchGesture,
    trackDoubleTapGesture,
  } = useAnalyticsContext();

  const { showButtons, setShowButtons, setMoveToSection } = useAppContext();

  const { unityProvider, isLoaded, sendMessage, addEventListener, removeEventListener } = useUnityContext({
    loaderUrl: "/static/unity/Build/002.loader.js",
    dataUrl: "/static/unity/Build/002.data",
    frameworkUrl: "/static/unity/Build/002.framework.js",
    codeUrl: "/static/unity/Build/002.wasm",
    adjustCanvasToContainer: true,
  });

  useEffect(() => {
    if (inputData !== undefined && inputData.hasOwnProperty("EventName")) {
      sendMessage(
        "DataReceiver",
        "LoadCustomEventData",
        JSON.stringify(inputData)
      );
    }
  }, [inputData, sendMessage]);

  useEffect(() => {
    if(isLoaded){

    }
  },[]);

  useEffect(() => {
    if(cardImages && cardImages.length > 0){
      projectDataModel.templateType = cardFormat;
      projectDataModel.cardPanelData[0].panelImage = cardImages[0];
      projectDataModel.cardPanelData[1].panelImage = cardImages[1];
      console.log("PROJECT DATA:");
      console.log(projectDataModel);
      setProjectData(projectDataModel);
    }

  }, []);

  const [unityIsLoaded, setUnityIsLoaded] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [viewed, setProjectViewed] = useState(false);
  const [animationState, setAnimationState] = useState(null);
  const animationTime = useRef(0.0);

  const sendUnityContent = () => {
    console.log("SENDING UNITY CONTENT");
    sendMessage(
      "DataReceiver",
      "LoadCustomizedData",
      JSON.stringify(projectData)
    );

    trackPageLoaded();
  };

  window.sendUnityContent = sendUnityContent;

  const GetAnimationTime = () => {
    return animationTime.current;
  };

  const handleAnimationConfirmation = () => {
    setProjectViewed(true);
    trackAnimationPlayCompleted();
    setAnimationState(null);
  };

  const handleEventTrigger = (eventDataString) => {
    const eventData = JSON.parse(eventDataString);
    const { EventName, EventValue } = eventData;
    switch (EventName) {
      case "AnimationState":
        setAnimationState(EventValue);
        break;

      case "AnimationTime":
        const eventFloatVal = parseFloat(EventValue);
        animationTime.current = eventFloatVal;
        break;

      case "ActionDoubleTap":
        const zoomDirection = (EventValue) => {
          if (EventValue === "DirectionIn") {
            return "zoom in";
          } else if (EventValue === "DirectionOut") {
            return "zoom out";
          }
        };
        trackDoubleTapGesture(zoomDirection);
        break;

      case "ActionPinch":
        const pinchDirection = (EventValue) => {
          if (EventValue === "DirectionIn") {
            return "zoom in";
          } else if (EventValue === "DirectionOut") {
            return "zoom out";
          }
        };
        trackPinchGesture(pinchDirection);
        break;

      default:
        break;
    }
  };

  const handleDataReceiverReady = () => {
    console.log("DATA RECEIVER READY");
    setUnityIsLoaded(true);
    sendUnityContent();
  }

  const handleMoveToMoreSection = () => {
    setMoveToSection("more");
  };

  const handleMoveToCoverSection = () => {
    setMoveToSection("coverSilent");
  }

  const handleEnableButtons = () => {
    setShowButtons(true);
  };

  const handleDisableButtons = () => {
    setShowButtons(false);
  };

  useEffect(() => {
    if (isLoaded) {
      addEventListener("debug", console.log);
      addEventListener("progress", setLoadingProgression);
      addEventListener("AnimationConfirmation", handleAnimationConfirmation);
      addEventListener("DisableButtons", handleDisableButtons);
      addEventListener("EnableButtons", handleEnableButtons);
      addEventListener("MoveToMoreSection", handleMoveToMoreSection);
      addEventListener("MoveToCoverSection", handleMoveToCoverSection);
      addEventListener("EventTrigger", handleEventTrigger);
      addEventListener("DataReceiverReady", handleDataReceiverReady);  
    }

    return () => {
      removeEventListener("debug", console.log);
      removeEventListener("progress", setLoadingProgression);
      removeEventListener("AnimationConfirmation", handleAnimationConfirmation);
      removeEventListener("DisableButtons", handleDisableButtons);
      removeEventListener("EnableButtons", handleEnableButtons);
      removeEventListener("MoveToMoreSection", handleMoveToMoreSection);
      removeEventListener("MoveToCoverSection", handleMoveToCoverSection);
      removeEventListener("EventTrigger", handleEventTrigger);
      removeEventListener("DataReceiverReady", handleDataReceiverReady);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (recipientName !== "" && dataLayerAvailable === true) {
      window.addEventListener("beforeunload", (event) => {
        trackAbandon(GetAnimationTime());
      });
    }
  }, [recipientName, dataLayerAvailable]);

  useEffect(() => {
    switch (animationState) {
      case "Start":
        trackAnimationPlayStart();
        break;

      default:
        console.log("Play state reset");
        break;
    }
  }, [animationState]);

  useEffect(() => {
    if (showButtons) {
      setViewStyle(defaultViewStyle);
    } else {
      setViewStyle(fullViewStyle);
    }
  }, [showButtons]);

  return (
    <div className="contentContainer">
      {!unityIsLoaded && (
        <>
          <LoadingIndicator
            title="Loading Digital Greeting"
            description="Your Digital Greeting is Loading"
            role="img"
            addClass=""
          />
        </>
      )}
      <div className="animationContent" style={viewStyle}>
        <Unity
          unityProvider={unityProvider}
          className={"animationContainer " + (unityIsLoaded ? "show" : "hidden")}
        />
      </div>
    </div>
  );
};

export default StaticUnityViewWithCustomName;
