import "./UnityView.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";
import { LoadingIndicator } from "@hallmark/web.core.feedback.loading-indicator";
import { GetBaseUrl } from "../../utils/Environment";
import { useAnalyticsContext } from "../../context/analytics-context";
import { useAnalytics } from "../../context/analytics-context/analytics-context-hooks";
import { useAppContext } from "../../context/app-context/app-context";

const UnityView = (props) => {
  const { inputData, token } = props;
  const [unityReady, setUnityReady] = useState(false);
  const { dataLayerAvailable } = useAnalytics();
  const [loadingProgression, setLoadingProgression] = useState(0);

  const defaultViewStyle = {
    height: "60vh",
  };

  const fullViewStyle = {
    height: "75vh",
  };

  const [viewStyle, setViewStyle] = useState(defaultViewStyle);

  const {
    trackAbandon,
    trackAnimationPlayStart,
    trackAnimationPlayCompleted,
    trackAnimationLoadFailure,
    trackPageLoaded,
    trackPinchGesture,
    trackDoubleTapGesture,
    trackError,
    setProductID,
    setProjectID,
    createStatusMessage,
    ACTIONS,
  } = useAnalyticsContext();

  const { showButtons, setShowButtons, setMoveToSection } = useAppContext();

  const { unityProvider, isLoaded, sendMessage, addEventListener, removeEventListener } = useUnityContext({
    loaderUrl: "/static/unity/Build/002.loader.js",
    dataUrl: "/static/unity/Build/002.data",
    frameworkUrl: "/static/unity/Build/002.framework.js",
    codeUrl: "/static/unity/Build/002.wasm",
    adjustCanvasToContainer: true,
  });

  useEffect(() => {
    if (inputData !== undefined) {
      sendMessage(
        "DataReceiver",
        "LoadCustomEventData",
        JSON.stringify(inputData)
      );
    }
  }, [inputData, sendMessage]);

  const handleAnimationConfirmation = () => {
    setProjectViewed(true);
    trackAnimationPlayCompleted();
    setAnimationState(null);
  };

  const handleEventTrigger = (eventDataString) => {
    const eventData = JSON.parse(eventDataString);
    const { EventName, EventValue } = eventData;
    switch (EventName) {
      case "AnimationState":
        setAnimationState(EventValue);
        break;

      case "AnimationTime":
        const eventFloatVal = parseFloat(EventValue);
        animationTime.current = eventFloatVal;
        break;

      case "ActionDoubleTap":
        const zoomDirection = (EventValue) => {
          if (EventValue === "DirectionIn") {
            return "zoom in";
          } else if (EventValue === "DirectionOut") {
            return "zoom out";
          }
        };
        trackDoubleTapGesture(zoomDirection);
        break;

      case "ActionPinch":
        const pinchDirection = (EventValue) => {
          if (EventValue === "DirectionIn") {
            return "zoom in";
          } else if (EventValue === "DirectionOut") {
            return "zoom out";
          }
        };
        trackPinchGesture(pinchDirection);
        break;

      default:
        break;
    }
  };

  const handleDataReceiverReady = () => {
    setUnityIsLoaded(true);
    sendUnityContent();
  }

  const handleMoveToMoreSection = () => {
    setMoveToSection("more");
  };

  const handleMoveToCoverSection = () => {
    setMoveToSection("coverSilent");
  }

  const handleEnableButtons = () => {
    setShowButtons(true);
  };

  const handleDisableButtons = () => {
    setShowButtons(false);
  };

  useEffect(() => {
    if(isLoaded){
      addEventListener("debug", console.log);
      addEventListener("progress", setLoadingProgression);
      addEventListener("AnimationConfirmation", handleAnimationConfirmation);
      addEventListener("DisableButtons", handleDisableButtons);
      addEventListener("EnableButtons", handleEnableButtons);
      addEventListener("MoveToMoreSection", handleMoveToMoreSection);
      addEventListener("MoveToCoverSection", handleMoveToCoverSection);
      addEventListener("EventTrigger", handleEventTrigger);
      addEventListener("DataReceiverReady", handleDataReceiverReady);  
    }

    return () => {
      removeEventListener("debug", console.log);
      removeEventListener("progress", setLoadingProgression);
      removeEventListener("AnimationConfirmation", handleAnimationConfirmation);
      removeEventListener("DisableButtons", handleDisableButtons);
      removeEventListener("EnableButtons", handleEnableButtons);
      removeEventListener("MoveToMoreSection", handleMoveToMoreSection);
      removeEventListener("MoveToCoverSection", handleMoveToCoverSection);
      removeEventListener("EventTrigger", handleEventTrigger);
      removeEventListener("DataReceiverReady", handleDataReceiverReady);
    }
  }, [isLoaded]);

  useEffect(() => {
    console.log("TOKEN: " + token);
    console.log("UNITY READY: " + isLoaded);
    if(token && isLoaded){
      FetchProjectData();
    }
  }, [token, isLoaded]);

  // console.log("UNITY COMPONENT");
  // console.log(UnityComponent);

  // console.log("UNITY COMPONENT TYPE");
  // console.log(typeof Unity.default);

  const [unityIsLoaded, setUnityIsLoaded] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [assetData, setAssetData] = useState({});
  const [viewReported, setViewReported] = useState(false);
  const [viewed, setProjectViewed] = useState(false);
  const [animationState, setAnimationState] = useState(null);
  const animationTime = useRef(0.0);

  const sendUnityContent = () => {
    console.log("Sending project data to Unity runtime");
    console.log(JSON.stringify(projectData));
    sendMessage(
      "DataReceiver",
      "LoadCustomizedDataFromURL",
      JSON.stringify(projectData)
    );

    trackPageLoaded();
  };

  window.sendUnityContent = sendUnityContent;

  const projectDataModel = {
    projectId: "123456",
    templateType: "portrait",
    previewMode: false,
    showCoverPreview: false,
    qualityLevel: 1,
    //2 - 0.5
    //4 - 0.65
    //5 - 0.75
    //6 - 0.85
    cardPanelData: [
      {
        index: 0,
        panelUrl:
          "https://content.hallmark.com/webassets/PGM1251/PGM1251_P1_Background.png",
      },
      {
        index: 1,
        panelUrl:
          "https://content.hallmark.com/webassets/PGM1251/PGM1251_P2-3_Background.png",
      },
    ],
  };

  function FetchProjectData() {
    console.log("Getting project data");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const endPoint = GetBaseUrl() + "/customization/recipient-assets";
    const patchEndpoint =
      GetBaseUrl() + "/customization/recipient-assets/view-date";
    axios
      .get(endPoint, config)
      .then((res) => {
        const resData = res.data.data;
        setViewReported(resData.viewed);
        setAssetData(res.data);

        projectDataModel.templateType = resData.card_format;
        projectDataModel.cardPanelData[0].panelUrl = resData.assets[0];
        projectDataModel.cardPanelData[1].panelUrl = resData.assets[1];

        setProjectData(projectDataModel);

        if (resData.project_id !== undefined && resData.project_id !== "") {
          setProjectID(resData.project_id);
        }

        if (resData.product_id !== undefined && resData.product_id !== "") {
          setProductID(resData.product_id);
        }

        setUnityIsLoaded(true);
      })
      .catch((err) => {
        const actionName = ACTIONS.LOAD_ASSETS;
        const messages = createStatusMessage(err);
        trackError(messages, actionName);
      });
  }

  function updateProjectView() {
    const patchEndpoint =
      GetBaseUrl() + "/customization/recipient-assets/view-date";

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .patch(patchEndpoint, null, config)
      .then((res) => {
        console.log(res);
        setViewReported(true);
      })
      .catch((err) => console.log(err));
  }

  const GetAnimationTime = () => {
    return animationTime.current;
  };

  useEffect(() => {
    if (JSON.stringify(projectData) !== "{}") {
      sendUnityContent();
    }
  }, [projectData]);

  useEffect(() => {
    if (viewed && !viewReported) {
      updateProjectView();
    }
  }, [viewed]);

  useEffect(() => {
    // console.log("Data layer available? [" + dataLayerAvailable + "]");
    if (token !== "" && dataLayerAvailable === true) {
      
      window.addEventListener("beforeunload", (event) => {
        trackAbandon(GetAnimationTime());
      });
    }
  }, [token, dataLayerAvailable]);

  useEffect(() => {
    switch (animationState) {
      case "Start":
        trackAnimationPlayStart();
        break;

      default:
        console.log("Play state reset");
        break;
    }
  }, [animationState]);

  useEffect(() => {
    if (showButtons) {
      setViewStyle(defaultViewStyle);
    } else {
      setViewStyle(fullViewStyle);
    }
  }, [showButtons]);

  return (
    <div className="contentContainer">
      {!unityIsLoaded && (
        <>
          <LoadingIndicator
            title="Loading Digital Greeting"
            description="Your Digital Greeting is Loading"
            role="img"
            addClass=""
          />
          {/* <div className="progressLoadBarContainer">
            <div>
              Loading your greeting (
              {`${Math.round(loadingProgression * 100)}%`})
            </div>
            <div
              className="progressLoadBar"
              style={{ width: `${loadingProgression * 100}%` }}
            ></div>
          </div> */}
        </>
      )}
      <div className="animationContent" style={viewStyle}>
        <Unity
          unityProvider={unityProvider}
          className={"animationContainer " + (unityIsLoaded ? "show" : "hidden")}
        />
      </div>
    </div>
  );
};

export default UnityView;
