import "./MoreInfoView.scss";
import { useAnalyticsContext } from "../../context/analytics-context";

const MoreInfoView = () => {
  const { trackIOSAppDownloadButtonClick } = useAnalyticsContext();
  const handleClick = (url) => {
    trackIOSAppDownloadButtonClick();
    const win = window.open(url, "_blank");
    if (win !== undefined && win !== null) {
      win.focus();
    }
  };

  return (
    <div className="more-info-view-container">
      <div className="more-info-view-inner">
        <div className="more-info-item">
          <div className="icon-email-plane">
            <img src="/static/img/graphic-email.png" alt="Say thanks" />
          </div>
          <div className="section-header">Say thanks!</div>
          <div className="description-container">
            <div className="description">Create a card just for them!</div>
          </div>
          <div className="icon-app">
            <img
              src="/static/img/graphic-app-icon.png"
              alt="Download Hallmark Cards Now"
            />
          </div>
        </div>
        <hr />
        <div className="more-info-item">
          <div className="section-header">Download Hallmark Cards Now</div>
          <div className="description-container">
            <div className="description">
              Personalize cards and we’ll mail them for you, or send for free by
              text or email!
            </div>
          </div>
        </div>
        <div className="more-info-item">
          <div className="app-store-buttons">
            <button
                onClick={() => {
                  handleClick("https://apps.apple.com/us/app/hallmark-cards-now/id1673592071");
                }}
              >
                <img className="apple-store-button"
                  src="/static/img/button-appstore.svg"
                  alt="Download on the App Store"
                />
            </button>
            <button
              onClick={() => {
                handleClick("https://play.google.com/store/apps/details?id=com.hallmarkcardsnow.app");
              }}
            >
              <img className="google-play-button"
                src="/static/img/button-appstore-google.svg"
                alt="Download on the Google Play Store"
              />
            </button>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default MoreInfoView;
